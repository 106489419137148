import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  setDoc,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import axios from "axios";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APPLICATION_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const firebaseService = {
  addDocument: async (collectionName, data) => {
    try {
    } catch (err) {
      throw new Error(err.message);
    }
  },
  getDocument: async (collectionName, id) => {
    try {
      const docRef = doc(db, collectionName, id);
      const res = await getDoc(docRef);
      return res.data();
    } catch (err) {
      throw new Error(err.message);
    }
  },
  addAnyDocument: async (collectionName, data) => {
    try {
      const docRef = doc(collection(db, collectionName));
      const docData = {
        ...data,
        id: docRef.id,
      };
      return await setDoc(docRef, docData);
    } catch (err) {
      throw new Error(err.message);
    }
  },
  getDocuments: async (collectionName) => {
    try {
      const collectionRef = collection(db, collectionName);
      const querySnapshot = await getDocs(collectionRef);
      const documents = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return documents;
    } catch (err) {
      throw new Error(err.message);
    }
  },
};

export default firebaseService;
