import "./App.css";
import FormCard from "./components/FormCard";
import { Route, Routes } from "react-router-dom";
import UpdateProfile from "./pages/UpdateProfile";
import PageNotFound from "./components/Common/PageNotFound";
import FormCardDefault from "./components/FormCardDefault";
import SignUpConference from './components/SignUpConference';
import ExportConferenceMembers from "./pages/ExportConferenceMembers";
function App() {
  return (
    <>
      <Routes>
        <Route path="/conference/export_members" element={<ExportConferenceMembers />} />
        <Route path="/" element={<FormCardDefault />} />
        <Route path="/sign-up-conference" element={<SignUpConference />} />
        <Route path="/:userId" element={<FormCard />} />
        <Route path="/update-profile/:userId" element={<UpdateProfile />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
