import React, { useState } from "react";
import Resizer from "react-image-file-resizer";
import {
  Card,
  CardBody,
  Button,
  Row,
  Col,
  Input,
  Label,
  Container,
  UncontrolledAlert,
} from "reactstrap";

import { Formik } from "formik";
import * as Yup from "yup";
import Error from "../components/Common/Error";
// firebase db
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import justTapLogo from "../assets/cropped-logo-justtap.png";

// upload function
import { uploadImg } from "./Common/FileUpload";

const validationSchema = Yup.object().shape({
  // Title: Yup.string().required("Job Title is required"),
  email: Yup.string().required("Email is required"),
  firstName: Yup.string().required("First name is required"),
  // officeNumber: Yup.string().required("Office number is required"),
});


const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const FormCard = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [checkedBox, setCheckedBox] = useState(user?.showWebsiteIcon === undefined ? true : user?.showWebsiteIcon);

  // add document
  const addData = async (data) => {
    setLoading(true);
    let d = {...data, showWebsiteIcon: checkedBox};
    try {
      // profile and custom icon images uplaod
      if (typeof data.profilePic == "object") {
        let profileImgUrl = await uploadImg(data?.profilePic, "images");
        if (profileImgUrl) {
          d = {
            ...d,
            profilePic: profileImgUrl,
          };
        }
      }
      if (typeof data.customIcon == "object") {
        try {
          // Compress the icon image to be max size 600x600
          // const compressedCustomIcon = await resizeFile(data?.customIcon);
          let customImgUrl = await uploadImg(data.customIcon, "images");
          if (customImgUrl) {
            d = {
              ...d,
              customIcon: customImgUrl,
            };
          }
        } catch (error) {
          console.log(error);
        }
      } 
      if (typeof data.customPdf == "object") {
        try {
          let customPdfUrl = await uploadImg(data.customPdf, "images");
          if (customPdfUrl) {
            d = {
              ...d,
              customPdf: customPdfUrl,
            };
          }
        } catch (error) {
          console.log(error);
        }
      }
      // set user document
      await updateDoc(doc(db, "users", user.userId), {
        ...d,
      });

      const checkUserRef = doc(db, "users", `usr${user.userId}`);
      const checkUserDoc = await getDoc(checkUserRef);
      const checkUser = checkUserDoc.data();
      if (checkUser) {
        await updateDoc(checkUserRef, {
          ...d,
        });
      }

      // //
      setLoading(false);
      setSuccess(true);
      // setTimeout(() => {
      //   setSuccess(false);
      // }, 3000);
      window.location.reload(`https://www.justtap.us/${user?.userId}`);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setFailure(true);
      setTimeout(() => {
        setFailure(false);
      }, 3000);
    }
  };

  return (
    <Container>
      <Row>
        <Col md={12} lg={10} xl={8} className="mx-auto mt-5 mb-5">
          <Card className="shadow border-0">
            <CardBody>
              <h3 className="text-muted text-center mb-5">
                <img src={justTapLogo} height={100} alt="Just Tap" />
              </h3>
              <p className="text-center mt-5" style={{ color: "#2F2F2F" }}>
                Please fill out all the information you would like to display on
                your business card profile. <span style={{ fontStyle: 'italic' }}> Everything is <strong> optional </strong> unless marked otherwise.</span>
              </p>
              <Formik
                initialValues={{
                  Title: user?.Title || "",
                  address: user?.address || "",
                  city: user?.city || "",
                  companyName: user?.companyName || "",
                  contactType: user?.contactType || "",
                  country: user?.country || "",
                  coverPic: user?.coverPic || null,
                  email: user?.email || "",
                  facebookUrl: user?.facebookUrl || "",
                  firstName: user?.firstName || "",
                  instagramUrl: user?.instagramUrl || "",
                  linkedInUrl: user?.linkedInUrl || "",
                  mobileNumber: user?.mobileNumber || "",
                  officeNumber: user?.officeNumber || "",
                  profilePic: user?.profilePic || null,
                  secondName: user?.secondName || "",
                  state: user?.state || "",
                  tiktokUrl: user?.tiktokUrl || "",
                  website: user?.website || "",
                  zip: user?.zip || "",
                  whatsappNumber: user?.whatsappNumber || "",
                  twitterUrl: user?.twitterUrl || "",
                  youtubeUrl: user?.youtubeUrl || "",
                  venmoUrl: user?.venmoUrl || "",
                  customIconName: user?.customIconName || "",
                  customIconUrl: user?.customIconUrl || "",
                  customIcon: user?.customIcon || null,
                  showWebsiteIcon: user?.showWebsiteIcon || true,
                  customPdf: user?.customPdf || null,
                  customPdfName: user?.customPdfName || "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                  addData(values);
                }}
              >
                {({
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  touched,
                  errors,
                }) => {
                  return (
                    <div>
                      <Row className="d-flex justify-content-between">
                        <Col md="5">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              First Name <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="firstName"
                              className="form-control"
                              placeholder="firstName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.firstName}
                            />
                            {errors.firstName && touched.firstName && (
                              <Error text={errors.firstName} />
                            )}
                          </div>
                        </Col>
                        <Col md="5">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Last Name
                            </Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="secondName"
                              className="form-control"
                              placeholder="Last name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.secondName}
                            />
                            {errors.secondName && touched.secondName && (
                              <Error text={errors.secondName} />
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between">
                        <Col md="5">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Job Title
                            </Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="Title"
                              className="form-control"
                              placeholder="title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.Title}
                            />
                            {errors.Title && touched.Title && (
                              <Error text={errors.Title} />
                            )}
                          </div>
                        </Col>
                        <Col md="5">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Company Name
                            </Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="companyName"
                              className="form-control"
                              placeholder="company"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.companyName}
                            />
                            {errors.companyName && touched.companyName && (
                              <Error text={errors.companyName} />
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between">
                        <Col md="5">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">Email <span style={{ color: "red" }}>*</span></Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="email"
                              className="form-control"
                              placeholder="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            {errors.email && touched.email && (
                              <Error text={errors.email} />
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between">
                        <Col md="5">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Website
                            </Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="website"
                              className="form-control"
                              placeholder="http://..."
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.website}
                            />
                            {errors.website && touched.website && (
                              <Error text={errors.website} />
                            )}
                          </div>
                          Show Website Icon? 
                          
                        <Input type="checkbox" id="basicpill-packagedetails-input1" name="showWebsiteIcon" className="form-control" placeholder="website" style={{width: 20, height: 20}} onChange={()=> {setCheckedBox(!checkedBox)}} onBlur={handleBlur} value={values.showWebsiteIcon} checked={checkedBox} />
                        </Col>
                       
                      </Row>
                      <p className="text-center text-muted fw-bold fs-5 mt-3 mb-3">
                        Address
                      </p>
                      <Row className="d-flex justify-content-between">
                        <Col md="5">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Country
                            </Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="country"
                              className="form-control"
                              placeholder="country"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.country}
                            />
                            {errors.country && touched.country && (
                              <Error text={errors.country} />
                            )}
                          </div>
                        </Col>
                        <Col md="5">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">State</Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="state"
                              className="form-control"
                              placeholder="state"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.state}
                            />
                            {errors.state && touched.state && (
                              <Error text={errors.state} />
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between">
                        <Col md="5">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">City</Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="city"
                              className="form-control"
                              placeholder="city"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.city}
                            />
                            {errors.city && touched.city && (
                              <Error text={errors.city} />
                            )}
                          </div>
                        </Col>
                        <Col md="5">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">Zip</Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="zip"
                              className="form-control"
                              placeholder="zip"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.zip}
                            />
                            {errors.zip && touched.zip && (
                              <Error text={errors.zips} />
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between">
                        <Col md="5">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Street
                            </Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="address"
                              className="form-control"
                              placeholder="address"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.address}
                            />
                            {errors.address && touched.address && (
                              <Error text={errors.address} />
                            )}
                          </div>
                        </Col>
                      </Row>
                      <p className="text-center text-muted fw-bold fs-5 mt-3 mb-3">
                        Contacts
                      </p>
                      <Row className="d-flex justify-content-between">
                        <Col md="5">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Mobile Number
                            </Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="mobileNumber"
                              className="form-control"
                              placeholder="mobile"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.mobileNumber}
                            />
                            {errors.mobileNumber && touched.mobileNumber && (
                              <Error text={errors.mobileNumber} />
                            )}
                          </div>
                        </Col>
                        <Col md="5">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Office Number
                            </Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="officeNumber"
                              className="form-control"
                              placeholder="office"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.officeNumber}
                            />
                            {errors.officeNumber && touched.officeNumber && (
                              <Error text={errors.officeNumber} />
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between">
                        <Col md="5">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Whatsapp Number (Start with country code Eg. +260)
                            </Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="whatsappNumber"
                              className="form-control"
                              placeholder="whatsapp"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.whatsappNumber}
                            />
                            {errors.whatsappNumber &&
                              touched.whatsappNumber && (
                                <Error text={errors.whatsappNumber} />
                              )}
                          </div>
                        </Col>
                      </Row>
                      <p className="text-center text-muted fw-bold fs-5 mt-3 mb-3">
                        Social Urls
                      </p>
                      <Row className="d-flex justify-content-between">
                        <Col md="12">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Facebook
                            </Label>
                            <Input
                              type="url"
                              id="basicpill-packagedetails-input1"
                              name="facebookUrl"
                              className="form-control"
                              placeholder="http://facebook.com/user"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.facebookUrl}
                            />
                            {errors.facebookUrl && touched.facebookUrl && (
                              <Error text={errors.facebookUrl} />
                            )}
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Instagram
                            </Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="instagramUrl"
                              className="form-control"
                              placeholder="http://instagram.com/user"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.instagramUrl}
                            />
                            {errors.instagramUrl && touched.instagramUrl && (
                              <Error text={errors.instagramUrl} />
                            )}
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Titktok
                            </Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="tiktokUrl"
                              className="form-control"
                              placeholder="http://tiktok.com/user"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.tiktokUrl}
                            />
                            {errors.tiktokUrl && touched.tiktokUrl && (
                              <Error text={errors.tiktokUrl} />
                            )}
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              linkedIn
                            </Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="linkedInUrl"
                              className="form-control"
                              placeholder="linkedIn"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.linkedInUrl}
                            />
                            {errors.linkedInUrl && touched.linkedInUrl && (
                              <Error text={errors.linkedInUrl} />
                            )}
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Twitter
                            </Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="twitterUrl"
                              className="form-control"
                              placeholder="http://twitter.com/user"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.twitterUrl}
                            />
                            {errors.twitterUrl && touched.twitterUrl && (
                              <Error text={errors.twitterUrl} />
                            )}
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Youtube
                            </Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="youtubeUrl"
                              className="form-control"
                              placeholder="http://youtube.com/user"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.youtubeUrl}
                            />
                            {errors.youtubeUrl && touched.youtubeUrl && (
                              <Error text={errors.youtubeUrl} />
                            )}
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Venmo
                            </Label>
                            <Input
                              type="url"
                              id="basicpill-packagedetails-input1"
                              name="venmoUrl"
                              className="form-control"
                              placeholder="http://venmo.com/user"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.venmoUrl}
                            />
                            {errors.venmoUrl && touched.venmoUrl && (
                              <Error text={errors.venmoUrl} />
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <p className="text-center text-muted fw-bold fs-5 mt-3 mb-3">
                          Custom Link
                        </p>
                        <Col md="12">
                          {/* Add your own custom URL. Add a photo, a name, and a link */}
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Icon Name
                            </Label>
                            <Input type="text" id="basicpill-packagedetails-input1" name="customIconName" className="form-control" placeholder="Icon Name" onChange={handleChange} onBlur={handleBlur} value={values.customIconName} />
                          </div>

                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Enter your custom URL / Social Media Link </Label>
                            <Input type="text" id="basicpill-packagedetails-input1" name="customIconUrl" className="form-control" placeholder="http://..." onChange={handleChange} onBlur={handleBlur} value={values.customIconUrl} />
                            {errors.customIconUrl && touched.customIconUrl && <Error text={errors.customIconUrl} />}
                          </div>
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Add an Icon </Label>
                            <Input type='file' id="basicpill-packagedetails-input1" name="customIcon" className="form-control" placeholder="customIcon" onChange={(e) => { setFieldValue("customIcon", e.target.files[0]) }} onBlur={handleBlur} />
                            {errors.customIcon && touched.customIcon && <Error text={errors.customIcon} />}
                          </div>

                        </Col>
                      </Row>

                      <Row>
                        <p className="text-center text-muted fw-bold fs-5 mt-3 mb-3">
                          PDF (Resume, Menu, Etc.)
                        </p>
                        <Col md="12">
                          {/* Add your own custom URL. Add a photo, a name, and a link */}
                         <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Enter your custom PDF </Label>
                            <Input type="file" id="basicpill-packagedetails-input1" name="customPdf" className="form-control" placeholder="customPdf" onChange={(e) => { setFieldValue("customPdf", e.target.files[0]) }} onBlur={handleBlur} />
                            {errors.customPdf && touched.customPdf && <Error text={errors.customPdf} />}
                          </div>
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Add a Name </Label>
                            <Input type="text" id="basicpill-packagedetails-input1" name="customPdfName" className="form-control" placeholder="customPdfName" onChange={handleChange} onBlur={handleBlur} value={values.customPdfName} />
                            {errors.customPdfName && touched.customPdfName && <Error text={errors.customPdfName} />}
                          </div>
                        </Col>
                      </Row>

                      <p className="text-center text-muted fw-bold fs-5 mt-3 mb-3">
                        Profile
                      </p>
                      <Row className="d-flex justify-content-between">
                        <Col md="6">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Profile Picture
                            </Label>
                            <Input
                              type="file"
                              id="basicpill-packagedetails-input1"
                              name="profilePic"
                              className="form-control"
                              placeholder="profile picture"
                              onChange={(e) => {
                                setFieldValue("profilePic", e.target.files[0]);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.profilePic && touched.profilePic && (
                              <Error text={errors.profilePic} />
                            )}
                          </div>
                        </Col>

                      </Row>
                      <div className="d-flex justify-content-center mb-5 mt-5">
                        <Button
                          className="px-5 py-2"
                          color="danger"
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          {!loading ? "Update" : "Updating..."}
                        </Button>
                      </div>
                    </div>
                  );
                }}
              </Formik>
              {success && (
                <UncontrolledAlert color="success">
                  Profile Updated Successfully.
                </UncontrolledAlert>
              )}
              {failure && (
                <UncontrolledAlert color="danger">
                  Something went wrong! Try again.
                </UncontrolledAlert>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FormCard;
