import React from 'react';
import logo from '../assets/ConferenceLogo.png';
export default function ConfirmationPage({ submittedValues }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
      }}
    >
        <img src={logo} alt="Just Tap logo" style={{maxWidth: "96%", margin: 10}} />
      <div
        style={{
          borderRadius: '10px',
          border: '2px solid #000000',
          padding: '20px',
          textAlign: 'left',
          margin: 10,
        }}
      >
        
        <h2 style={{ color: '#000000' }}>Thank you for registering</h2>
        <p> We will verify the conference package you enrolled for before the conference begins.<br />
         For any corrections to your information, please email <a href="mailto:Orders.justtap@gmail.com">orders.justtap@gmail.com</a></p>
        {submittedValues.title && <p style={{ color: '#000000' }}>Title: {submittedValues.title}</p>}
        {submittedValues.address && <p style={{ color: '#000000' }}>Address: {submittedValues.address}</p>}
        {submittedValues.city && <p style={{ color: '#000000' }}>City: {submittedValues.city}</p>}
        {submittedValues.companyName && <p style={{ color: '#000000' }}>Company Name: {submittedValues.companyName}</p>}
        {submittedValues.contactType && <p style={{ color: '#000000' }}>Contact Type: {submittedValues.contactType}</p>}
        {submittedValues.country && <p style={{ color: '#000000' }}>Country: {submittedValues.country}</p>}
        {submittedValues.email && <p style={{ color: '#000000' }}>Email: {submittedValues.email}</p>}
        {submittedValues.facebookUrl && <p style={{ color: '#000000' }}>Facebook URL: {submittedValues.facebookUrl}</p>}
        {submittedValues.firstName && <p style={{ color: '#000000' }}>First Name: {submittedValues.firstName}</p>}
        
        {submittedValues.secondName && <p style={{ color: '#000000' }}>Last Name: {submittedValues.secondName}</p>}
        {submittedValues.instagramUrl && <p style={{ color: '#000000' }}>Instagram URL: {submittedValues.instagramUrl}</p>}
        {submittedValues.linkedInUrl && <p style={{ color: '#000000' }}>LinkedIn URL: {submittedValues.linkedInUrl}</p>}
        {submittedValues.mobileNumber && <p style={{ color: '#000000' }}>Mobile Number: {submittedValues.mobileNumber}</p>}
        {submittedValues.officeNumber && <p style={{ color: '#000000' }}>Office Number: {submittedValues.officeNumber}</p>}
        {submittedValues.state && <p style={{ color: '#000000' }}>State: {submittedValues.state}</p>}
        {submittedValues.tiktokUrl && <p style={{ color: '#000000' }}>TikTok URL: {submittedValues.tiktokUrl}</p>}
        {submittedValues.website && <p style={{ color: '#000000' }}>Website: {submittedValues.website}</p>}
        {submittedValues.zip && <p style={{ color: '#000000' }}>Zip: {submittedValues.zip}</p>}
        {submittedValues.whatsappNumber && <p style={{ color: '#000000' }}>WhatsApp Number: {submittedValues.whatsappNumber}</p>}
        {submittedValues.twitterUrl && <p style={{ color: '#000000' }}>Twitter URL: {submittedValues.twitterUrl}</p>}
        {submittedValues.youtubeUrl && <p style={{ color: '#000000' }}>Youtube URL: {submittedValues.youtubeUrl}</p>}
      </div>
    </div>
  );
}
