import { v4 } from "uuid";
import { storage, db, auth } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export async function uploadImg(file, path) {
  if (!file) {
    return;
  }
  try {
    const upload = await uploadBytes(ref(storage, `${path}/${v4()}`), file);
    const url = await getDownloadURL(upload.ref);
    return url;
  } catch (err) {
    throw new Error(err.message);
  }
}
