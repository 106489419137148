import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    Button,
    Row,
    Col,
    Input,
    Label,
    Container,
    UncontrolledAlert,
    ButtonGroup,
} from "reactstrap";
import ConferenceJustTapZimLogo from "../assets/ConferenceLogo.png";
import { Formik } from "formik";
import * as Yup from "yup";
import Error from "../components/Common/Error";
// firebase db
import { db } from "../firebase";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import firebaseService from "../services/firebase.services";
// upload function
import { uploadImg } from "./Common/FileUpload";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import ConfirmationPage from "./ConfirmationPage";

const validationSchema = Yup.object().shape({
    // Title: Yup.string().required("Job Title is required"),
    email: Yup.string().required("Email is required"),
    firstName: Yup.string().required("First name is required"),
    // secondName: Yup.string().required("Last name is required"),
    // orderId: Yup.string().required("Order ID is required"),
    // mobileNumber: Yup.string().required("Mobile number is required"),
});

const FormCard = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [currentUserId, setCurrentUserId] = useState("");
    const [radioButtonSelected, setRadioButtonSelected] = useState("None");
    const [conferenceLevelSelected, setConferenceLevelSelected] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [finalizedValues, setFinalizedValues] = useState({});
    function generateUserId() {
        const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        // Conferences use the `wrst` format.
        let randomUserId = '';

        // Generate a random character from the possibleChars array 12 times and add it to the user ID string
        for (let i = 0; i < 12; i++) {
            const randomIndex = Math.floor(Math.random() * possibleChars.length);
            randomUserId += possibleChars[randomIndex];
            // add in a dash every 4 characters
            if (i % 4 === 3 && i !== 11) {
                randomUserId += '-';
            }
        }
        return randomUserId;
    }
    // add document
    const addData = async (data) => {
        console.log("addData function")
        setLoading(true);
        let d = { ...data, conferencePackage: radioButtonSelected };
        try {
            // profile and cover images uplaod
            let profileImgUrl = await uploadImg(data.profilePic, "images");
            if (profileImgUrl) {
                d = {
                    ...d,
                    profilePic: profileImgUrl,
                };
            }
            setFinalizedValues(d);
            const password = Math.random()?.toString()?.slice(2, 10);
            const userId = currentUserId;
            const userRef = doc(db, "users", userId);
            await setDoc(userRef, {
                ...d,
                password: password,
                userId: userId,
                uidNumber: userId,
            });
            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };
            var Data = {
                service_id: process.env.REACT_APP_SERVICE_ID,
                template_id: "template_jma0kis",
                user_id: process.env.REACT_APP_USER_ID,
                template_params: {
                    password: password,
                    account_id: userId,
                    to_email: d.email,
                    profileLink: `https://profile.justtap.us/update-profile/${userId}`,
                },
            };
            const dataString = JSON.stringify(Data);
            // await axios.post(
            //     "https://api.emailjs.com/api/v1.0/email/send",
            //     (data = dataString),
            //     config
            // );
            // setLoading(false);
            // setSuccess(true);
            // setTimeout(() => {
            //     window.location.replace(`https://www.justtap.us/${userId}`);
            // }, 3000);
            setConfirmed(true);
        } catch (err) {
            console.log("Error K", err.message);
            setLoading(false);
            setFailure(true);
            setTimeout(() => {
                setFailure(false);
            }, 3000);
        }
    };
    const checkIsValidUserId = async () => {

        const userId = params.userId;
        if (userId) {
            console.log(userId);
            const user = await firebaseService.getDocument("users", userId);
            if (user) {
                window.location.replace(`https://www.justtap.us/${userId}`);
                return;
            }
        }
        const randomUserId = 'wrst' + generateUserId()
        // Define an array of all possible characters for the user ID

        // Check if this randomly generated user already exists (very unlikely)
        const userRef = doc(db, "users", randomUserId);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
            // Crash the app if this happens
            throw new Error('Internal Server Error. Please reload');
        }

        setPageLoading(false);
        setCurrentUserId(randomUserId);
        return true;
    };

    useEffect(() => {
        checkIsValidUserId();
    }, []);

    return (
        <>
            {confirmed && (<ConfirmationPage submittedValues={finalizedValues} />)} {!confirmed && (
                <Container>

                    {pageLoading && (
                        <div
                            style={{
                                height: "100vh",
                                width: "100vw",
                            }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            >
                                <Oval
                                    height={80}
                                    width={80}
                                    color="#c09851"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel="oval-loading"
                                    secondaryColor="#c09851;"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                                />
                            </div>
                        </div>
                    )}

                    <Row>

                        <Col md={12} lg={10} xl={8} className="mx-auto mt-5">

                            <Card className="shadow border-0">
                                <CardBody>
                                    <h3 className="text-muted text-center mb-5">
                                        <img src={ConferenceJustTapZimLogo} style={{ maxWidth: "100%" }}

                                            alt="Just Tap" />
                                    </h3>
                                    <p className="text-center mt-5" style={{ color: "#8F8F8F" }}>
                                        Please fill out all the information you would like to display on
                                        your conference / business card profile
                                    </p>

                                    <div className="mb-3">
                                        <Label for="basicpill-address-input1">
                                            Conference Package Level
                                        </Label>
                                        {/* I would like to make the following four buttons be rows, one button per row */}
                                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                                             {/* <ButtonGroup style={{flexWrap: 'wrap' }}> */}
                                            <Button style={{
                                                color: 'black', backgroundColor: radioButtonSelected === 'Awards Ceremony ONLY' ? 'lightgreen' : '#cccccc',
                                                border: radioButtonSelected === 'Awards Ceremony ONLY' ? '5px solid #37C538' : '1px solid black', maxWidth: 200,
                                                fontSize: 12, margin: 4,
                                            }} onClick={() => { setRadioButtonSelected("Awards Ceremony ONLY"); setConferenceLevelSelected(true); }} active={radioButtonSelected === "Awards Ceremony ONLY"}>Awards Ceremony ONLY </Button>

                                            <Button style={{
                                                color: 'black', backgroundColor: radioButtonSelected === 'Conference Plus' ? 'lightgreen' : '#cccccc', maxWidth: 150,fontSize: 12, margin: 4,
                                                border: radioButtonSelected === 'Conference Plus' ? '5px solid #37C538' : '1px solid black'
                                            }} onClick={() => { setRadioButtonSelected("Conference Plus"); setConferenceLevelSelected(true); }} active={radioButtonSelected === "Conference Plus"}>Conference Plus</Button>

                                            <Button style={{
                                                color: 'black', backgroundColor: radioButtonSelected === 'Full Package (AVANI)' ? 'lightgreen' : '#cccccc', maxWidth: 200,fontSize: 12,
                                                border: radioButtonSelected === 'Full Package (AVANI)' ? '5px solid #37C538' : '1px solid black', margin: 4,

                                            }} onClick={() => { setRadioButtonSelected("Full Package (AVANI)"); setConferenceLevelSelected(true); }} active={radioButtonSelected === "Full Package (AVANI)"}>Full Package (AVANI)</Button>

                                            <Button style={{
                                                backgroundColor: radioButtonSelected === 'Full Package (RADISSON)' ? 'lightgreen' : '#cccccc', maxWidth: 200,fontSize: 12,
                                                color: 'black', margin: 4,
                                                border: radioButtonSelected === 'Full Package (RADISSON)' ? '5px solid green' : '1px solid black'
                                            }} onClick={() => { setRadioButtonSelected("Full Package (RADISSON)"); setConferenceLevelSelected(true); }} active={radioButtonSelected === "Full Package (RADISSON)"}>Full Package (RADISSON)</Button>
                                        {/* </ButtonGroup> */}
                                        </div>


                                        <p className='m-2'>Selected: <b>{radioButtonSelected}</b></p>
                                    </div>
                                    {/* </Col> */}
                                    {/* END RADIO BUTTON */}
                                    {conferenceLevelSelected && (
                                        <Formik
                                            initialValues={{
                                                orderId: "bankOrderOne",
                                                Title: "",
                                                address: "",
                                                city: "",
                                                companyName: "",
                                                contactType: "",
                                                country: "",
                                                coverPic: null,
                                                email: "",
                                                facebookUrl: "",
                                                firstName: "",
                                                instagramUrl: "",
                                                linkedInUrl: "",
                                                mobileNumber: "",
                                                officeNumber: "",
                                                profilePic: null,
                                                secondName: "",
                                                state: "",
                                                tiktokUrl: "",
                                                website: "",
                                                zip: "",
                                                whatsappNumber: "",
                                                twitterUrl: "",
                                                youtubeUrl: "",
                                            }}
                                            validationSchema={validationSchema}
                                            onSubmit={(values, { resetForm }) => {
                                                console.log("values", values);
                                                addData(values).then(response => {
                                                    if (response) {
                                                        console.log("Success");
                                                        resetForm();
                                                    }
                                                })
                                            }}



                                        >
                                            {({
                                                setFieldValue,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                values,
                                                touched,
                                                errors,
                                            }) => {
                                                return (
                                                    <div>
                                                        {/* <Row className="d-flex justify-content-between">
                        <Col md="5">
                          <div className="mb-3">
                            <Label for="basicpill-address-input1">
                              Order ID
                            </Label>
                            <Input
                              type="text"
                              id="basicpill-packagedetails-input1"
                              name="orderId"
                              className="form-control"
                              placeholder="order id"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.orderId}
                            />
                            {errors.orderId && touched.orderId && (
                              <Error text={errors.orderId} />
                            )}
                          </div>
                        </Col>
                      </Row> */}
                                                        <Row className="d-flex justify-content-between">

                                                            <Col md="5">
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-address-input1">
                                                                        First Name <span style={{color: 'red'}}>*</span>
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="basicpill-packagedetails-input1"
                                                                        name="firstName"
                                                                        className="form-control"
                                                                        placeholder="first name"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.firstName}
                                                                    />
                                                                    {errors.firstName && touched.firstName && (
                                                                        <Error text={errors.firstName} />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col md="5">
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-address-input1">
                                                                        Last Name
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="basicpill-packagedetails-input1"
                                                                        name="secondName"
                                                                        className="form-control"
                                                                        placeholder="last name"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.secondName}
                                                                    />
                                                                    {errors.secondName && touched.secondName && (
                                                                        <Error text={errors.secondName} />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-between">
                                                            <Col md="5">
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-address-input1">
                                                                        Job Title
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="basicpill-packagedetails-input1"
                                                                        name="Title"
                                                                        className="form-control"
                                                                        placeholder="job title"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.Title}
                                                                    />
                                                                    {errors.Title && touched.Title && (
                                                                        <Error text={errors.Title} />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col md="5">
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-address-input1">
                                                                        Company Name
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="basicpill-packagedetails-input1"
                                                                        name="companyName"
                                                                        className="form-control"
                                                                        placeholder="company"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.companyName}
                                                                    />
                                                                    {errors.companyName && touched.companyName && (
                                                                        <Error text={errors.companyName} />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-between">
                                                            {/* <Col md="5">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-address-input1">
                                                            Contact Type
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="basicpill-packagedetails-input1"
                                                            name="contactType"
                                                            className="form-control"
                                                            placeholder="contact type"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.contactType}
                                                        />
                                                        {errors.contactType && touched.contactType && (
                                                            <Error text={errors.contactType} />
                                                        )}
                                                    </div>
                                                </Col> */}
                                                            <Col md="5">
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-address-input1">Email  <span style={{color: 'red'}}>*</span></Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="basicpill-packagedetails-input1"
                                                                        name="email"
                                                                        className="form-control"
                                                                        placeholder="email"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.email}
                                                                    />
                                                                    {errors.email && touched.email && (
                                                                        <Error text={errors.email} />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-between">
                                                            <Col md="5">
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-address-input1">
                                                                        Website
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="basicpill-packagedetails-input1"
                                                                        name="website"
                                                                        className="form-control"
                                                                        placeholder="website"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.website}
                                                                    />
                                                                    {errors.website && touched.website && (
                                                                        <Error text={errors.website} />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <p className="text-center text-muted fw-bold fs-5 mt-3 mb-3">
                                                            Contacts
                                                        </p>
                                                        <Row className="d-flex justify-content-between">
                                                            <Col md="5">
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-address-input1">
                                                                        Mobile Number
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="basicpill-packagedetails-input1"
                                                                        name="mobileNumber"
                                                                        className="form-control"
                                                                        placeholder="mobile"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.mobileNumber}
                                                                    />
                                                                    {errors.mobileNumber && touched.mobileNumber && (
                                                                        <Error text={errors.mobileNumber} />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col md="5">
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-address-input1">
                                                                        Office Number
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="basicpill-packagedetails-input1"
                                                                        name="officeNumber"
                                                                        className="form-control"
                                                                        placeholder="office"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.officeNumber}
                                                                    />
                                                                    {errors.officeNumber && touched.officeNumber && (
                                                                        <Error text={errors.officeNumber} />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-between">
                                                            <Col md="5">
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-address-input1">
                                                                        Whatsapp Number (Start with country code Eg. +260)
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="basicpill-packagedetails-input1"
                                                                        name="whatsappNumber"
                                                                        className="form-control"
                                                                        placeholder="whatsapp"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.whatsappNumber}
                                                                    />
                                                                    {errors.whatsappNumber &&
                                                                        touched.whatsappNumber && (
                                                                            <Error text={errors.whatsappNumber} />
                                                                        )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <p className="text-center text-muted fw-bold fs-5 mt-3 mb-3">
                                                            Social Urls (Optional but recommended for networking)
                                                        </p>
                                                        <Row className="d-flex justify-content-between">
                                                            <Col md="12">
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-address-input1">
                                                                        Facebook
                                                                    </Label>
                                                                    <Input
                                                                        type="url"
                                                                        id="basicpill-packagedetails-input1"
                                                                        name="facebookUrl"
                                                                        className="form-control"
                                                                        placeholder="http://facebook.com/your-profile-link"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.facebookUrl}
                                                                    />
                                                                    {errors.facebookUrl && touched.facebookUrl && (
                                                                        <Error text={errors.facebookUrl} />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col md="12">
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-address-input1">
                                                                        Instagram
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="basicpill-packagedetails-input1"
                                                                        name="instagramUrl"
                                                                        className="form-control"
                                                                        placeholder="http://instagram.com/your-profile-link"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.instagramUrl}
                                                                    />
                                                                    {errors.instagramUrl && touched.instagramUrl && (
                                                                        <Error text={errors.instagramUrl} />
                                                                    )}
                                                                </div>
                                                            </Col>

                                                            <Col md="12">
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-address-input1">
                                                                        LinkedIn
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="basicpill-packagedetails-input1"
                                                                        name="linkedInUrl"
                                                                        className="form-control"
                                                                        placeholder="linkedin.com/in/your-profile-link"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.linkedInUrl}
                                                                    />
                                                                    {errors.linkedInUrl && touched.linkedInUrl && (
                                                                        <Error text={errors.linkedInUrl} />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col md="12">
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-address-input1">
                                                                        Twitter
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="basicpill-packagedetails-input1"
                                                                        name="twitterUrl"
                                                                        className="form-control"
                                                                        placeholder="http://twitter.com/your-profile-link"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.twitterUrl}
                                                                    />
                                                                    {errors.twitterUrl && touched.twitterUrl && (
                                                                        <Error text={errors.twitterUrl} />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col md="12">
                                                            </Col>
                                                        </Row>
                                                        <p className="text-center text-muted fw-bold fs-5 mt-3 mb-3">
                                                            Profile
                                                        </p>
                                                        <Row className="d-flex justify-content-between">
                                                            <Col md="6">
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-address-input1">
                                                                        Profile Picture
                                                                    </Label>
                                                                    <Input
                                                                        type="file"
                                                                        id="basicpill-packagedetails-input1"
                                                                        name="profilePic"
                                                                        className="form-control"
                                                                        placeholder="profile picture"
                                                                        onChange={(e) => {
                                                                            setFieldValue("profilePic", e.target.files[0]);
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                    {errors.profilePic && touched.profilePic && (
                                                                        <Error text={errors.profilePic} />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <div className="d-flex justify-content-center mb-5 mt-5">
                                                            <Button
                                                                className="px-5 py-2"
                                                                color="danger"
                                                                type="submit"
                                                                onClick={handleSubmit}
                                                                disabled={loading}
                                                            >
                                                                {!loading ? "Submit" : "Submiting..."}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        </Formik>
                                    )}
                                    {success && (
                                        <UncontrolledAlert color="success">
                                            Data added successfully. Check your email for credentials.
                                        </UncontrolledAlert>
                                    )}
                                    {failure && (
                                        <UncontrolledAlert color="danger">
                                            Something went wrong! Try again.
                                        </UncontrolledAlert>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
};

export default FormCard;
