// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCruLXSh5Gx0QSMcMGWW2kyhEPhi_X0Slw",
  authDomain: "tafika-profile.firebaseapp.com",
  databaseURL: "https://tafika-profile-default-rtdb.firebaseio.com",
  projectId: "tafika-profile",
  storageBucket: "tafika-profile.appspot.com",
  // projectId: process.env.REACT_APP_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_APPLICATION_ID,
};

const app = initializeApp(firebaseConfig, 'myApp');
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
