import React, { useState } from "react";
import { Row, Col, Card, CardBody, Alert, Container } from "reactstrap";
 
const UpdateProfile = ({ user, authenticateHandler }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  function handleSubmit() {
    if (password == user.password) {
      authenticateHandler();
    } else {
      setError(true);
    }
  }
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="p-3">
                    <h3 className="text-center mb-4">JustTap</h3>
                    {error ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        Wrong Password!s
                      </Alert>
                    ) : (
                      <div className="alert alert-info" role="alert">
                        Enter your Password to update profile!
                      </div>
                    )}
                    <div className="mb-3 mt-5">
                      <input
                        name="password"
                        label="Password"
                        className="form-control"
                        placeholder="Enter Password"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    <Row>
                      <Col className="text-end">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </Col>
                    </Row>
                    
                  </div>
                </CardBody>
                
              </Card>
              {/* Centered div with a link to the user's profile  */}
              <div className="mt-5 text-center">
                <p>
                  <a href={"https://www.justtap.us/" + user.userId} className="text-muted">
                    <i className="mdi mdi-lock me-1"></i> Preview your profile here.
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UpdateProfile;
