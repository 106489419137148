import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import EditProfileCard from "../components/EditFormCard";
import PasswordValidate from "../components/PasswordValidate";
import Loader from "../components/Common/Loader";
import { Row, Col, Container, UncontrolledAlert } from "reactstrap";
// firebase db
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import "./Loader.css";

const UpdateProfile = () => {
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);
  const [authenticate, setAuthenticate] = useState(false);
  const [user, setUser] = useState({});
  let { userId } = useParams();

  function authenticateHandler() {
    setAuthenticate(true);
  }
  useEffect(() => {
    setLoading(true);
    try {
      const getUser = async () => {
        const userCountDocRef = doc(db, "users", userId);
        const docSnap = await getDoc(userCountDocRef);
        const docData = docSnap.data();
        if (docData) {
          setUser(docData);
        }
        if (!docData) {
          setFailure(true);
        }
        setLoading(false);
      };
      getUser();
    } catch (err) {
      setLoading(false);
      setFailure(true);
    }
  }, []);

  return (
    <React.Fragment>
      {!authenticate && !loading ? (
        <PasswordValidate
          user={user}
          authenticateHandler={authenticateHandler}
        />
      ) : authenticate && !loading ? (
        <EditProfileCard user={user} />
      ) : (
        <div className="loaderClass">
          {" "}
          <Loader className="loaderClass" />
        </div>
      )}
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            {failure && (
              <UncontrolledAlert color="danger">
                User Not Found!
              </UncontrolledAlert>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default UpdateProfile;
