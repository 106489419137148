// The goal is to export all conference members, who are 
// in the database under 'users' table and their userID begins with `wrst`
// const user = await firebaseService.getDocument("users", userId);
// Sign in with the AdminUser credentials using firebase

import React from "react";
import { db } from "../firebase";
import { doc, setDoc, getDoc, updateDoc, getDocs, collection } from "firebase/firestore";
import firebaseService from "../services/firebase.services";
export default function ExportConferenceMembers() {
    const [password, setPassword] = React.useState("");
    const [passwordCorrect, setPasswordCorrect] = React.useState(false);
    const [export_loading, setExportLoading] = React.useState(false);
    const test_password = async () => {
        console.log("Testing password")
        const admin = (await getDoc(doc(db, "users", "admin-DO-NOT-DELETE"))).data();
        // access the password somehow?
        if (admin.password === password) {
            console.log("Password is correct")
            setPasswordCorrect(true);
        }
        else {
            alert("Password is incorrect");
        }
    }

    const getAllDocs = async (collectionPath) => {
        const querySnapshot = await getDocs(collection(db, collectionPath));
        const docs = querySnapshot.docs.map((doc) => doc.data());
        return docs;
      }
    const export_members = async () => {
        console.log("Exporting members")
        setExportLoading(true);
        const users = await getAllDocs("users");
        console.log(users);
        const conference_users = users.filter(user => {
            return user.userId && user.userId.startsWith("wrst") && (!user.firstName.includes("TestUser"));
        } );

        console.log(conference_users);
        // Make an excel table with the following columns
        // Name, Email, Package Level, Day 1 Present, Day 2 Present, Day 3 Present, Day 4 Present
        // Have the default row value be filled by the user's information
        // name <=> user.firstName + user.secondName
        // conferencePackage <=> user.conferencePackage
        // email <=> user.email
        // Day 1 present should be a drop down, default false
        // Day 2 present should be a drop down, default false
        // Day 3 present should be a drop down, default false
        // Day 4 present should be a drop down, default false
        // The excel table should be downloadable
        // The excel table should be editable
        conference_users.sort((a, b) => {
            if (a.secondName < b.secondName) {
                return -1;
            }
            else if (a.secondName > b.secondName) {
                return 1;
            }
            else {
                return 0;
            }
        })

        // First iteration, create a CSV
        let csv_info = "Num, Email, FirstName, LastName, Phone, Company Name, Package Level,Day 1 Present,Day 2 Present,Day 3 Present,Day 4 Present\n"
        conference_users.forEach((user, index) => {
            // csv_info += `${user.firstName},${user.secondName},${user.email},${user.conferencePackage}, , , , \n`;
            csv_info += `${index},${user.email},${user.firstName},${user.secondName},${user.mobileNumber},${user.companyName},${user.conferencePackage}, , , , \n`;
        })
        // Sort the users by name
       
        // Create a CSV file
        const csv = new Blob([csv_info], {type: "text/csv"});
        const url = URL.createObjectURL(csv);
        const link = document.createElement("a");

        // Set the file name and download
        link.setAttribute("href", url);
        // Date and Time of export
        const title = "ConferenceMembers " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString() + ".csv";
        link.setAttribute("download", title);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setExportLoading(false);
    }
    return (
        <div>
            <h1>Export Conference Members</h1>
            
            <div style={{border: '1px solid black', padding: 20, margin: 20}}>
                <p style={{marginBottom: 20 }}>Enter the admin password</p>
                <input style={{marginBottom: 20 }} type="password" value={password} onChange={e => setPassword(e.target.value)} />
                <button onClick={test_password}>Enter Password</button>
                {passwordCorrect &&<div>
                    
                     <p>Password correct</p>
                     <button style={{backgroundColor: 'green', bordeRadius: 5, margin: 20}} onClick={export_members}>Export</button>
                     {export_loading && <p>Exporting...</p>}
                     </div>}
                
            </div>
        </div>
    )
};

